import OutSideDataApiSet from '@/request/api/OutSideDataApiSet';
export default {
  data () {
    return {
      tableColumns: [
        BaseTableColumn.initData('', '123').setSelection().setWidth(32).setAlign('center'),
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('concat', '操作人').setWidth(230).setKeyValueList([
          { label: '名称：', labelWidth: 100, key: 'operateName', attr: { canTooltip: true } },
          { label: '手机：', labelWidth: 100, key: 'operateTel', attr: { canHidePhone: true } }
        ]),
        BaseTableColumn.initData('importDate', '导入时间', 140).setSpan({}),
        BaseTableColumn.initData('status', '数据状态', 100).setStatus([
          { value: 0, type: 1 },
          { value: 1, type: 4 },
          { value: 2, type: 2 }
        ], 'statusDesc'),
        BaseTableColumn.initData('concat', '处理结果').setWidth(200).setKeyValueList([
          { label: '成功：', labelWidth: 100, key: 'h5SuccessAmountStr', attr: { className: 'text-color-finish' } },
          { label: '失败：', labelWidth: 100, key: 'h5ErrorAmountStr', attr: { className: 'text-color-error' } }
        ]),
        BaseTableColumn.initData('setting', '下载').setWidth(180).setAlign('center').setButtons([
          { title: '失败文档', name: '2' },
          { title: '成功文档', name: '1' }
        ], ({ name, title }, row, index) => {
          if (name === '1' && !row.dealSuccessNum) {
            return this.$Message.warning('无成功文档');
          }
          if (name === '2' && !row.dealFailNum) {
            return this.$Message.warning('无失败文档');
          }
          this.requestDownload({
            status: name,
            externalDataType: '4', // 外部数据类型：2-小橙设备台账 3-友电设备台账 4-银行打款凭证
            id: row.id
          }, title);
        })
      ],
      page: { current: 1, total: 0 },
      tableData: [],
      settingExportIdList: [],
      settingExportCondition: {}
    };
  },
  created () {},
  methods: {
    /** ******************* 回调事件开始 ******************* **/
    onChangePage (page) {
      this.page.current = page;
      this.requestData();
    },
    onChangeSelection (list) {
      // 1.先把本页的数据清掉
      let selectList = this.settingExportIdList.filter(v => this.tableData.findIndex(v1 => v1._checkId === v._checkId) === -1);
      if (list.length > 0) {
        // 本页有勾选项放进去
        selectList = [...selectList, ...list];
      }
      this.settingExportIdList = selectList;
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    _clickSearch (option) {
      this.searchOption = option;
      this.page.current = 1;
      this.requestData();
    },
    /** ******************* 点击事件结束 ******************* **/
    requestImport (params) {
      const api = OutSideDataApiSet.dataImport;
      const formDt = new FormData();
      formDt.append('file', params.file);
      formDt.append('externalDataType', params.externalDataType);
      api.params = formDt;
      this.$httpFormData(api).then(res => {
        this.$Message.success('导入成功');
        this.dialog.submitModel = null;
        this.onChangePage(1);
      });
    }
  }
};
