export class BankCardRefundDataModel extends BaseModel {
  constructor () {
    super();
    this.id = '';
    this.operateName = ''; // 操作人名称
    this.operateTel = ''; // 操作人手机
    this.importDate = ''; // 导入时间
    this.status = ''; // 数据状态(0：待处理，1: 处理中，2：已处理)
    this.statusDesc = ''; // 数据状态转义
    this.dealSuccessNum = ''; // 处理结果-成功条数
    this.dealFailNum = ''; // 处理结果-失败条数
    // 前端专用 ==============>
    // 是否已选中
    this._checked = false;
    // 勾选的主键
    this._checkId = '';
    this.h5SuccessAmountStr = ''; // 页面中展示的成功条数
    this.h5ErrorAmountStr = ''; // 页面中展示的失败条数
    // 前端专用 ==============>
  }

  initData (resData) {
    super.initData(resData);
    this._checkId = this.id;
    switch (this.status) {
    case 2:
      this.h5SuccessAmountStr = this.dealSuccessNum + '条';
      this.h5ErrorAmountStr = this.dealFailNum + '条';
      break;
    default:
      this.h5SuccessAmountStr = '-';
      this.h5ErrorAmountStr = '-';
      break;
    }
    return this;
  }

  static checkModelIsSelect (dataList, selectList) {
    const len = dataList.length;
    for (let i = 0; i < len; i++) {
      const model = dataList[i];
      model._checked = !!selectList.find(v => v._checkId === model._checkId);
    }
  }

  static createFakeDataList (len = 30) {
    const list = [];
    const statusDesc = ['待处理', '处理中', '已处理'];
    for (let i = 0; i < len; i++) {
      const dic = {
        id: '000101010' + i,
        operateName: BaseModel.getFakeTitle(3),
        operateTel: '1379740022' + i,
        importDate: '2024-03-0' + BaseModel.getFakeNumber(8, 0),
        status: i % 3,
        statusDesc: statusDesc[i % 3],
        dealSuccessNum: 100,
        dealFailNum: i % 5 ? 600 : 0
      };
      list.push(dic);
    }
    return list;
  }
}

export class VoucherImportFormModel extends BaseModel {
  constructor () {
    super();
    this.file = null;
    this.externalDataType = '4'; // 外部数据类型：2-小橙设备台账 3-友电设备台账 4-银行打款凭证 5-用户台账 6-手机号变更台账
  }

  checkValidSubmit () {
    return BaseModel.getValidRes(true, '', {
      externalDataType: this.externalDataType,
      file: this.file
    });
  }

  static createAdditionModel () {
    return new this();
  }
}
