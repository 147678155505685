/**
 * @date:2024/1/31
 * @author 金星晖
 * @copyright 南京云柜<yun-gui.com>
 * 外部数据
 */

import BaseApi from '@/request/base/BaseApi';

class OutSideDataApiSet {
  constructor () {
    this.dataImport = BaseApi.createModel('数据导入接口', '/opmp/admin/externalChannel/dataImport', true).setMethod(1);
    this.qryImportData = BaseApi.createModel('数据列表查询接口', '/opmp/admin/externalChannel/qryImportData', true).setMethod(1);
    this.downloadDeviceResult = BaseApi.createModel('下载设备处理结果接口', '/opmp/admin/externalChannel/downloadDealResult', true).setMethod(1);
    this.downloadDealResult = BaseApi.createModel('下载处理结果接口', '/opmp/admin/externalChannel/downloadDealResult', true).setMethod(1);
  }
}

export default new OutSideDataApiSet();
