<!--
    Created by 王丽莎 on 2024/05/15.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    界面名称：财务管理-充电币退款（银行卡）
-->
<style lang="less">
#bank-card-refund-manage {
  .text-color-error {
    color: @errorColor;
    font-weight: 600;
  }
}
</style>

<template>
  <section id="bank-card-refund-manage">
    <!-- 查询条件 -->
    <div>
      <yg-search-area
        v-permission="'system:bankCardRefund:list'"
        :show-info="false"
        :value="searchOption"
        @on-search="_clickSearch"
      />
    </div>
    <div>
      <yg-setting-area
        :items="settingItems"
        :total="page.total"
        :export-amount="settingExportIdList.length"
        :export-select-id-list="settingExportIdList"
        :export-condition="settingExportCondition"
        @on-click="_clickSetting"
      />
    </div>
    <div
      v-permission="'system:bankCardRefund:list'"
      class="padding-primary-top"
    >
      <yg-table
        :columns="tableColumns"
        :data="tableData"
        :page="page"
        @on-page-change="onChangePage"
        @on-selection="onChangeSelection"
      />
    </div>
    <!--导入弹窗-->
    <v-submit
      v-if="!!dialog.submitModel"
      :model="dialog.submitModel"
      @on-close="dialog.submitModel=null"
      @on-sure="onSureSubmit"
    />
  </section>
</template>

<script>
import vSubmit from './view/submit.vue';
import { BankCardRefundDataModel, VoucherImportFormModel } from './model/BankCardRefundDataModel';
import controllerMixins from './mixins/controller';
import pageTableExpandMixin from '@/libs/mixins/pageTableExpand';
import OutSideDataApiSet from '@/request/api/OutSideDataApiSet';
import { downloadExcel } from '@/libs/download';

export default {
  components: { vSubmit },
  mixins: [controllerMixins, pageTableExpandMixin],
  data () {
    return {
      searchOption: {
        importDate: BaseSearchModel.initData('导入日期', 'importDate', 'DatePickerRange').setDatePickerElevator([])
      },
      settingItems: [
        BaseSettingModel.initData('导入打款凭证', 'import', '').setPermission('system:externalChannel:dataImport'),
        BaseSettingModel.initData('导出记录表', 'export', 'md-cloud-download').setExport().setExportReportType(18).setPermission('system:bankCardRefund:export')
      ],
      dialog: {
        submitModel: null
      }
    };
  },
  created () {
  },
  methods: {
    /** ******************* 工具方法开始 ******************* **/
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    onSureSubmit (params) {
      // for (let i = 0; i < 10; i++) {
      this.requestImport(params);
      // }
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    _clickSetting (key) {
      if (key === 'import') {
        this.dialog.submitModel = VoucherImportFormModel.createAdditionModel();
      }
    },
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    requestData () {
      this.tableData = [];
      this.page.total = 0;
      const api = OutSideDataApiSet.qryImportData;
      const s = this.searchOption;
      api.params = {
        externalDataType: '4',
        importDateBegin: s.importDate.value[0] || '',
        importDateEnd: s.importDate.value[1] || '',
        pageSize: this.$config.pageSize,
        pageNo: this.page.current
      };
      this.settingExportCondition = api.params;
      console.log(api.params);
      this.$http(api).then(res => {
        const resData = res.data;
        this.page.total = resData.total;
        this.tableData = BankCardRefundDataModel.initListWithDataList(resData.rows);
        BankCardRefundDataModel.checkModelIsSelect(this.tableData, this.settingExportIdList);
      });

      // const resList = BankCardRefundDataModel.createFakeDataList(10);
      // this.page.total = resList.length;
      // this.tableData = BankCardRefundDataModel.initListWithDataList(resList);
      // BankCardRefundDataModel.checkModelIsSelect(this.tableData, this.settingExportIdList);
    },
    requestDownload (params, title) {
      const api = OutSideDataApiSet.downloadDealResult;
      api.params = params;
      downloadExcel(api.url, api.params, title).then().catch(e => {
        this.$Message.warning(e);
      });
    }
  }
};
</script>
