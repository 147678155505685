<!--
    Created by 王丽莎 on 2023/12/28.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：银行卡退款管理 - 导入打款凭证
    功能概述：导入数据
-->
<style lang="less">
.voucher-import-submit-content {
  padding-bottom: @containerGap;
  .ivu-form {
    .ivu-form-item {
      display: block;
      .ivu-form-item-label {
        margin-bottom: 5px;
        text-align: left;
        display: block;
        float: none;
      }
    }
  }
  .upload-box-outer {
    width: 100%;
  }
}
</style>

<template>
  <yg-modal
    width="460"
    title="导入打款凭证"
    sure-text="提交"
    :sure-button-disabled="buttonDisabled"
    :sure-button-disabled-text="buttonDisabledMsg"
    @on-sure="onSure"
    @on-close="onClose"
  >
    <div
      slot="content"
      style="min-height: 200px"
    >
      <div class="voucher-import-submit-content">
        <Form>
          <FormItem
            v-for="(item, index) in formItems"
            :key="index"
            :label="item.label"
            :required="item.isRequired"
          >
            <div
              v-if="item.tagName === 'Upload'"
              class="upload-box-outer"
            >
              <yg-choose-excel
                template-url="/opmp/admin/externalChannel/templateDownload"
                :template-name="'模版'"
                :template-params="{templateType: model.externalDataType}"
                @on-change="onChooseExcel"
              />
            </div>
          </FormItem>
        </Form>
      </div>
    </div>
  </yg-modal>
</template>

<script>

export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      buttonDisabled: true,
      buttonDisabledMsg: '',
      // 表单数据
      formItems: []
    };
  },
  created () {
    this.formItems = [
      {
        label: '上传文件',
        key: 'file',
        tagName: 'Upload',
        isRequired: true
      }
    ];
    this.checkSubmit();
  },
  methods: {
    onSure () {
      // 请求导入
      this.$emit('on-sure', this.model);
      // // console.log(this.model);
      // const res = this.model.checkValidSubmit();
      // if (!res.success) {
      //   this.$Message.warning(res.msg);
      // } else {
      //   // 请求导入
      //   this.$emit('on-sure', this.model);
      // }
    },
    onClose () {
      this.$emit('on-close');
    },
    // 选择excel
    onChooseExcel (file) {
      this.model.file = file;
      this.checkSubmit();
    },
    // 检查必填项
    checkSubmit () {
      const item = this.formItems.find(v => v.isRequired && !this.model[v.key]);
      this.buttonDisabled = !!item;
      this.buttonDisabledMsg = item ? (item.label + '不可为空') : '';
    }
  }
};
</script>
